import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionJoin = () => {
    return (
        <section>
            <div className="container py-5 px-4 px-lg-5">
                <h2 className="fw-bold text-center mb-5">Yuk Gabung di PZN <span className="text-primary">Expert</span></h2>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="text-muted lh-lg">Belajar sepuasnya, tidak ada batas waktu. Beli sekali akses seumur hidup. Materi update terus mengikuti perkembangan zaman dan tren industri, tanpa tambahan biaya.</p>
                        <ul className="text-muted lh-lg">
                            <li>1000+ Materi Video Pembelajaran</li>
                            <li>92+ Jam Durasi Belajar</li>
                            <li>45 Modul</li>
                            <li>1x Bayar, Akses + Update Materi Seumur Hidup</li>
                        </ul>
                        <a className="btn btn-outline-dark py-2 px-3 rounded-1" href="https://www.youtube.com/watch?v=v4ydosgdAJo" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="me-2" icon={faPlayCircle} />
                            Demo Materi
                        </a>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-1.png" alt="Join 1" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 text-muted my-auto">
                        <p className="lh-lg">Alur belajar terarah disusun secara komprehensif oleh para expert.Nggak usah pusing-pusing lagi mikirin roadmap belajar, tinggal ikutin aja materinya satu persatu sampai selesai.100% teruji ampuh!</p>
                        <p className="lh-lg">Pemula, belum pernah belajar coding dan nggak punya background IT juga bisa gabung. Diajarin dari 0 banget!</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-2.png" alt="Join 2" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 text-muted my-auto">
                        <p className="lh-lg">Error, stuck atau punya kendala saat belajar ngikutin materi? Langsung tanyain aja di Forum Tanya Jawab!</p>
                        <p className="lh-lg">Pertanyaan-pertanyaanmu akan dijawab secara sigap oleh para mentor.</p>
                        <p className="lh-lg">Forum Tanya Jawab aktif pada jam kerja.</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-join-3.png" alt="Join 3" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionJoin;
