const SectionFasilitas = () => {
    return (
        <section className="bg-primary text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-between">
                    <div className="col-lg-6 my-auto mb-5 mb-lg-auto">
                        <h2 className="fw-bold mb-5">Fasilitas Belajar</h2>
                        <p>Yang akan kamu dapatkan saat kamu gabung dalam program ini</p>
                        <ul className="lh-lg">
                            <li>Akses Materi + Update Materi Seumur Hidup</li>
                            <li>2900+ Materi Video Pembelajaran</li>
                            <li>Akses Materi Bisa Dimana Saja</li>
                            <li>Fitur Bertanya</li>
                            <li>Sertifikat Digital</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-fasilitas.png" alt="Fasilitas Belajar" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionFasilitas;
