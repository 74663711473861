import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardRoadmap from "../CardRoadmap/CardRoadmap";
import CardBundle from "../CardBundle/CardBundle";

const SectionInvest = () => {
    const roadmaps = [
        {
            title: 'Jagoan Java',
            thumbnail: '/assets/img/roadmaps/jagoan-java.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-java/'
        },
        {
            title: 'Jagoan Javascript',
            thumbnail: '/assets/img/roadmaps/jagoan-javascript.png',
            normalPrice: 599000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-javascript/'
        },
        {
            title: 'Jagoan Golang',
            thumbnail: '/assets/img/roadmaps/jagoan-golang.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-go-lang/'
        },
        {
            title: 'Jagoan Kotlin',
            thumbnail: '/assets/img/roadmaps/jagoan-kotlin.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-kotlin/'
        },
        {
            title: 'Jagoan Dart',
            thumbnail: '/assets/img/roadmaps/jagoan-dart.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-dart/'
        },
        {
            title: 'Jagoan Git',
            thumbnail: '/assets/img/roadmaps/jagoan-git.png',
            normalPrice: 599000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-git/'
        },
        {
            title: 'Jagoan MySQL',
            thumbnail: '/assets/img/roadmaps/jagoan-mysql.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-mysql/'
        },
        {
            title: 'Jagoan Springboot',
            thumbnail: '/assets/img/roadmaps/jagoan-springboot.png',
            normalPrice: 599000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-spring-boot/'
        },
        {
            title: 'Jagoan NodeJs',
            thumbnail: '/assets/img/roadmaps/jagoan-nodejs.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-nodejs/'
        },
        {
            title: 'Jagoan Spring Boot Design Pattern',
            thumbnail: '/assets/img/roadmaps/jagoan-springboot-design-pattern.png',
            normalPrice: 499000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-spring-boot-design-patterns/'
        },
        {
            title: 'Jagoan Android',
            thumbnail: '/assets/img/roadmaps/jagoan-android.png',
            normalPrice: 519000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-android/'
        },
        {
            title: 'Jagoan Laravel',
            thumbnail: '/assets/img/roadmaps/jagoan-laravel.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/jagoan-laravel/'
        },
        {
            title: 'Jagoan Jenkins',
            thumbnail: '/assets/img/roadmaps/jagoan-jenkins.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/jagoan-jenkins/'
        },
        {
            title: 'Jagoan PHP',
            thumbnail: '/assets/img/roadmaps/jagoan-php.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-php/'
        },
        {
            title: 'Jagoan Docker',
            thumbnail: '/assets/img/roadmaps/jagoan-docker.png',
            normalPrice: 649000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/pzn-jagoan-docker/'
        },
        {
            title: 'Jagoan PostgreSQL',
            thumbnail: '/assets/img/roadmaps/jagoan-postgresql.png',
            normalPrice: 249000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/jagoan-postgresql/'
        },
        {
            title: 'Jagoan Typescript',
            thumbnail: '/assets/img/roadmaps/jagoan-typescript.png',
            normalPrice: 249000,
            retailPrice: 159000,
            link: 'https://www.codepolitan.com/roadmap/jagoan-typescript/'
        },
    ];

    const bundles = [
        {
            title: 'Bundling PHP + MySQL + Laravel',
            description: 'Belajar paket lengkap auto terarah mulai dari bahasa pemrogramman PHP, database SQL hingga framework Laravel.',
            thumbnail: '/assets/img/bundlings/bundling-1.png',
            normalPrice: 1947000,
            retailPrice: 299000,
            link: 'https://pay.codepolitan.com/?slug=pzn-bundling-php-mysql-laravel'
        },
        {
            title: 'Bundling Java + Spring Boot + Spring Boot Design Patterns',
            description: 'Belajar paket lengkap auto terarah mulai dari bahasa pemrogramman Java, Spring Boot dan Spring Boot Design Patterns',
            thumbnail: '/assets/img/bundlings/bundling-2.png',
            normalPrice: 1747000,
            retailPrice: 299000,
            link: 'https://pay.codepolitan.com/?slug=pzn-bundling-java-spring-boot-spring-boot-design-pattern'
        },
        {
            title: 'Bundling Java + Kotlin + Dart + Android',
            description: 'Belajar paket lengkap auto terarah mulai dari bahasa pemrogramman Java, Kotlin, Dart dan Android',
            thumbnail: '/assets/img/bundlings/bundling-3.png',
            normalPrice: 2546000,
            retailPrice: 399000,
            link: 'https://pay.codepolitan.com/?slug=pzn-bundling-java-kotlin-dart-android'
        },
        {
            title: 'Bundling JavaScript + NodeJS',
            description: 'Belajar paket lengkap auto terarah mulai dari bahasa pemrogramman Javascript dan NodeJS',
            thumbnail: '/assets/img/bundlings/bundling-4.png',
            normalPrice: 1248000,
            retailPrice: 199000,
            link: 'https://pay.codepolitan.com/?slug=pzn-bundling-javascript-nodejs'
        },
    ];

    return (
        <section className="bg-light" id="invest">
            <div className="container py-5 px-4 px-lg-5">
                <h2 className="fw-bolder text-center">Pilihan Program dan Investasi</h2>
                <p className="text-center text-muted mb-5">Intip rangkaian materi belajar yang akan kamu pelajari di program ini:</p>

                <div className="row mb-5">
                    {roadmaps?.map((roadmap, index) => {
                        return (
                            <div className="col-md-6 col-lg-4 col-xl-3 p-3" key={index}>
                                <a className="text-decoration-none text-dark" href={roadmap.link} target="_blank" rel="noopener noreferrer">
                                    <CardRoadmap
                                        thumbnail={roadmap.thumbnail}
                                        title={roadmap.title}
                                        normalPrice={roadmap.normalPrice}
                                        retailPrice={roadmap.retailPrice}
                                    />
                                </a>
                            </div>
                        );
                    })}
                </div>

                <h2 className="fw-bolder text-center mb-5">Mau Lebih Hemat?</h2>
                <div className="row mb-5">
                    {bundles?.map((bundle, index) => {
                        return (
                            <div className="col-md-6 col-lg-4 col-xl-3 p-3" key={index}>
                                <CardBundle
                                    thumbnail={bundle.thumbnail}
                                    title={bundle.title}
                                    normalPrice={bundle.normalPrice}
                                    retailPrice={bundle.retailPrice}
                                    description={bundle.description}
                                    link={bundle.link}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="card border-0 rounded-3" style={{ borderRadius: '15px' }}>
                            <div className="card-header bg-primary text-white" style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                                <h2 className="text-center">Bundling Super Hemat</h2>
                            </div>
                            <div className="card-body text-center">
                                <img className="img-fluid mb-5" src="/assets/img/img-bundling.png" alt="Bundling" />
                                <p className="text-danger h5">
                                    <del>Rp.9.803.000</del>
                                </p>
                                <p className="display-5 fw-bolder">Rp.1.599.000</p>
                                <p className="text-muted">Bundling Semua Kelas</p>
                                <div className="d-grid px-5 mb-3">
                                    <a className="btn btn-primary btn-lg" href="https://pay.codepolitan.com/?slug=pzn-expert" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon className="me-2" icon={faBookmark} />
                                        Beli Bundling
                                    </a>
                                </div>
                                <p className="text-muted">Kamu akan mendapatkan semua kelas lengkap dari program PZNEXPERT ini dengan harga yang super hemat!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionInvest;
