const SectionIntro = () => {
    return (
        <section className="bg-primary text-white" style={{ backgroundImage: `url('/assets/img/bg-intro.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-center">
                    <div className="col-lg-5 my-auto mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-intro.png" loading="lazy" alt="Intro" />
                    </div>
                    <div className="col-lg-5 my-auto">
                        <ul className="lh-lg">
                            <li>Pengen punya karir bagus dan punya gaji tinggi, biar kalau beli sesuatu nggak harus pusing mikirin harga?</li>
                            <li>Pengen punya kesempatan bekerja di tech company atau startup unicorn, supaya nggak bingung kalau ditanya tetangga?</li>
                            <li>Pengen punya keahlian yang banyak dicari industri, nggak mudah diganti sama robot atau AI dan tetap relevan sama perkembangan zaman?</li>
                            <li>Pengen jadi freelancer, biar bisa bebas kerja dimana dan kapan saja sesuai kemauan? Yang penting cuan YGY?</li>
                            <li>Pengen bisa kerja remote dari rumah tapi gaji dolar?</li>
                            <li>Pengen jadi boss dan bisa ngembangin aplikasi atau web sendiri? Siapa tau jadi Mark Zuckerberg atau Elon Musk selanjutnya</li>
                        </ul>
                        <p className="fw-bolder">Kalau jawabannya Iya, mungkin program ini bisa jadi solusi buat kamu!</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionIntro;
