import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionBenefits from '../../components/home/SectionBenefits/SectionBenefits';
import SectionFaq from '../../components/home/SectionFaq/SectionFaq';
import SectionFasilitas from '../../components/home/SectionFasilitas/SectionFasilitas';
import SectionIntro from '../../components/home/SectionIntro/SectionIntro';
import SectionInvest from '../../components/home/SectionInvest/SectionInvest';
import SectionJoin from '../../components/home/SectionJoin/SectionJoin';
import SectionAbout from '../../components/home/SectionAbout/SectionAbout';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>PZN EXPERT - ProgrammerZamanNow</title>
            </Helmet>
            <Layout>
                <Hero />
                <SectionIntro />
                <SectionAbout />
                <SectionJoin />
                <SectionFasilitas />
                <SectionInvest />
                <SectionBenefits />
                <SectionFaq />
            </Layout>
        </>
    );
};

export default Home;
