import { faCodeBranch, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import styles from './Navbar.module.scss';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <nav className="navbar navbar-light navbar-expand-lg bg-white fixed-top">
            <div className="container py-2 px-4 px-lg-5">
                <HashLink className="navbar-brand" to="/#top">
                    <img className={styles.img_logo} src="/assets/img/logo-pznexpert.png" alt="PZN Expert" />
                </HashLink>
                <button className="navbar-toggler shadow-0 border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <Hamburger size={23} toggled={isOpen} toggle={setOpen} title="menu" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 me-lg-4 mb-lg-0">
                        <li className="nav-item">
                            <HashLink className="nav-link" to="#faq">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Tanya CS
                            </HashLink>
                        </li>
                    </ul>
                    <div className="d-grid gap-2 d-md-block">
                        <a className="btn btn-outline-dark rounded-1 me-md-3 px-3 py-2" href="https://dashboard.codepolitan.com/login" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="me-2" icon={faRightToBracket} />
                            Login Member
                        </a>
                        <HashLink className="btn btn-primary rounded-1 px-3 py-2" to="#invest">
                            <FontAwesomeIcon className="me-2" icon={faCodeBranch} />
                            Gabung Kelas
                        </HashLink>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
