const Footer = () => {
    return (
        <footer>
            <section className="bg-primary text-white">
                <div className="container py-3 px-5">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <img height="25px" src="/assets/img/logo-pznexpert-white.png" alt="Logo" />
                        </div>
                        <div className="col-auto">
                            <p className="mb-0">&copy; 2023 PZN Expert. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default Footer;
