import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPrice } from "../../../utils";

const CardBundle = ({ thumbnail, title, normalPrice, retailPrice, description, link }) => {
    return (
        <div className="card border-0 h-100" style={{ borderRadius: '15px' }}>
            <img className="card-img-top" src={thumbnail} alt={title} style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }} />
            <div className="card-body">
                <del className="text-danger">Rp. {formatPrice(normalPrice)}</del>
                <h5 className="h4 fw-bolder">Rp. {formatPrice(retailPrice)}</h5>
                <div style={{ minHeight: '60px' }}>
                    <p className="small text-muted">{title}</p>
                </div>
                <div className="d-grid mb-3">
                    <a className="btn btn-primary" href={link} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="me-2" icon={faBookmark} />
                        Beli Bundling
                    </a>
                </div>
                <p className="small text-muted">{description}</p>
            </div>
        </div>
    );
};

export default CardBundle;
