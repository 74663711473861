import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionFaq = () => {
    const faqs = [
        {
            id: 1,
            question: 'Apakah program ini cocok untuk pemula yang belum memiliki pengalaman dalam coding?',
            answer: 'Tentu saja! Program ini dirancang untuk pemula yang ingin mempelajari coding dari dasar. Materi belajar terstruktur akan memandu anda melalui langkah-langkah yang jelas, sehingga anda dapat memulai karir jadi jagoan programmer anda tanpa masalah.'
        },
        {
            id: 2,
            question: 'Apa saja teknologi modern yang akan diajarkan dalam program ini?',
            answer: 'Program ini akan mengajarkan berbagai teknologi modern yang digunakan oleh startup keren di seluruh dunia. Beberapa di antaranya termasuk bahasa pemrograman populer seperti JavaScript, PHP, Kotlin, java dan lainnya serta framework populer seperti Laravel, Spring Boot dan lain lain'
        },
        {
            id: 3,
            question: 'Bagaimana cara saya mendapatkan bimbingan dari mentor?',
            answer: 'Selama program, Anda akan memiliki akses ke fitur bertanya pada platform CODEPOLITAN dan grup tanya jawab yang disediakan melalui platform Discord. Di sana, Anda dapat mengajukan pertanyaan kepada mentor dan juga berinteraksi dengan sesama peserta untuk mendapatkan bantuan dan dukungan.'
        },
        {
            id: 4,
            question: 'Apakah saya akan mendapatkan sertifikat setelah menyelesaikan program ini?',
            answer: 'Ya, setelah berhasil menyelesaikan program ini, anda akan menerima sertifikat resmi yang menunjukkan keberhasilan anda dalam mempelajari coding dan menguasai teknologi modern. Sertifikat ini dapat menjadi tambahan yang berharga untuk portofolio anda.'
        },
        {
            id: 5,
            question: 'Bagaimana program ini membantu dalam mencari pekerjaan di industri teknologi?',
            answer: 'Program ini memberikan Anda keterampilan coding yang solid dan pemahaman mendalam tentang teknologi modern yang dicari oleh perusahaan teknologi. Dengan ini, Anda akan memiliki keunggulan kompetitif dalam mencari pekerjaan di industri teknologi yang kompetitif.'
        },
        {
            id: 6,
            question: 'Apakah saya bisa belajar program ini dengan waktu yang fleksibel?',
            answer: 'Tentu saja! Program ini disajikan dalam format kelas online yang memungkinkan anda untuk belajar sesuai dengan jadwal anda sendiri. anda dapat mengakses materi belajar kapan saja dan di mana saja, sehingga dapat menyesuaikan waktu belajar dengan kebutuhan dan kenyamanan anda.'
        },
        {
            id: 7,
            question: 'Apakah ada dukungan teknis yang tersedia selama program ini?',
            answer: 'Ya, selama program ini, tim dukungan teknis kami akan siap membantu Anda dalam mengatasi masalah teknis atau pertanyaan terkait platform pembelajaran. Anda dapat menghubungi mereka melalui email atau melalui fitur diskusi yang disediakan di platform CODEPOLITAN.'
        },
        {
            id: 8,
            question: 'Apakah ada opsi pembayaran yang tersedia untuk program ini?',
            answer: 'Ya, kami menawarkan berbagai opsi pembayaran yang fleksibel. Anda dapat memilih paket pembelajaran yang sesuai dengan kebutuhan dan anggaran Anda. Kami menerima pembayaran dengan kartu kredit, transfer bank, atau menggunakan platform pembayaran online yang populer.'
        },
        {
            id: 9,
            question: 'Bagaimana cara mendaftar untuk program ini?',
            answer: 'Untuk mendaftar, anda dapat klik tombol gabung kelas dibagian atas. Di sana, Anda akan diarahkan ke website CODEPOLITAN dan diminta untuk mengisi formulir pendaftaran dengan informasi pribadi dan memilih paket pembelajaran yang diinginkan. Setelah itu, Anda akan mendapatkan panduan langkah demi langkah untuk memulai program.'
        },
        {
            id: 10,
            question: 'Apakah ada jaminan kepuasan atau pengembalian dana?',
            answer: 'Kami sangat percaya dengan kualitas program ini. Namun, jika Anda tidak puas dengan pengalaman belajar dalam 3 hari setelah memulai program, kami menawarkan jaminan kepuasan dan akan mengembalikan dana Anda sepenuhnya.'
        }
    ];

    return (
        <section id="faq">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="fw-bold">Pertanyaan yang Sering Ditanyakan</h2>
                    <p className="text-muted lh-lg">Simak beberapa pertanyaan-pertanyaan berikut, siapa tahu salah satunya adalah pertanyaan yang ingin kamu tanyakan.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="accordion accordion-flush" id="customAccordion">
                            {faqs.map((faq) => {
                                return (
                                    <div className="accordion-item border-0 mb-1" key={faq.id}>
                                        <h2 className="accordion-header" id={`flush-heading${faq.id}`}>
                                            <button className={`accordion-button ${faq.id !== 1 ? 'collapsed' : ''} shadow-none fw-bolder`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${faq.id}`} aria-expanded={faq.id === 1 ? "true" : "false"} aria-controls={`flush-collapse${faq.id}`}>
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div id={`flush-collapse${faq.id}`} className={`accordion-collapse collapse ${faq.id === 1 ? 'show' : ''}`} aria-labelledby={`flush-heading${faq.id}`} data-bs-parent="#customAccordion">
                                            <div className="accordion-body">
                                                <p className="text-muted lh-lg">{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="text-center my-5">
                    <p className="lh-lg fw-bolder text-muted">Punya pertanyaan lain?</p>
                    <a className="btn btn-outline-dark py-2 px-4" href="https://wa.me/628999488990?text=Hai Saya ingin bergabung dengan program PZN Expert, mohon dibantu" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                        Tanya CS
                    </a>
                </div>
            </div>
        </section>
    );
};

export default SectionFaq;
