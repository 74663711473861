import styles from './CardRoadmap.module.scss';
import { formatPrice } from '../../../utils';

const CardRoadmap = ({ thumbnail, title, normalPrice, retailPrice }) => {
    return (
        <div className={`card border-0 ${styles.card_roadmap}`}>
            <img className="card-img-top rounded" src={thumbnail} alt={title} />
            <div className="card-body">
                <p className="mb-0">
                    <del className="small text-danger">Rp. {formatPrice(normalPrice)}</del>
                </p>
                <div className="d-flex">
                    <span className="fw-bolder">Rp. {formatPrice(retailPrice)}</span>
                    <button className="btn btn-primary btn-sm ms-auto">Beli Roadmap</button>
                </div>
            </div>
        </div>
    );
};

export default CardRoadmap;
