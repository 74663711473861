import { faBriefcase, faLaptopCode, faListCheck, faSitemap, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionBenefits = () => {
    const benefits = [
        {
            icon: faLaptopCode,
            title: 'Belajar Coding dengan Cepat',
            content: 'Program ini dirancang untuk membantu peserta belajar coding dengan cepat dan efisien. Dengan bimbingan dan pengalaman lebih dari 15 tahun dari Eko Kurniawan Khannedy, peserta dapat mengembangkan keterampilan coding secara efektif dalam waktu singkat.'
        },
        {
            icon: faSitemap,
            title: 'Materi Belajar Terarah',
            content: 'Program ini menawarkan materi belajar yang terstruktur dan terarah. Peserta akan diajak melalui 15 roadmap belajar yang tersedia, memastikan mereka memahami konsep-konsep coding secara mendalam dan membangun fondasi yang kuat.'
        },
        {
            icon: faListCheck,
            title: 'Menguasai Teknologi Modern',
            content: 'Program ini fokus pada mengajarkan teknologi modern yang digunakan oleh startup keren di seluruh dunia. Dengan menguasai teknologi ini, peserta akan siap untuk terjun ke dunia industri yang selalu berkembang pesat.'
        },
        {
            icon: faUserTie,
            title: 'Mentor Berpengalaman',
            content: 'Eko Kurniawan Khannedy, sebagai mentor dalam program ini, memiliki pengalaman lebih dari 15 tahun di industri programming. Peserta akan mendapatkan bimbingan langsung dari seorang ahli yang telah mencapai kesuksesan dalam karirnya.'
        },
        {
            icon: faBriefcase,
            title: 'Bisa Bikin Produk dan Bisnis Online Sendiri',
            content: 'Keterampilan coding yang dikuasai melalui program ini membuka pintu peluang karir yang menjanjikan di industri teknologi. Peserta akan memiliki keunggulan kompetitif dalam mencari pekerjaan di perusahaan-perusahaan teknologi terkemuka.'
        }
    ];

    return (
        <section>
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="fw-bold">Apa Untungnya Belajar Bersama PZN X CODEPOLITAN ?</h2>
                </div>

                <div className="row justify-content-center">
                    {benefits.map((benefit, index) => {
                        return (
                            <div className="col-lg-6 col-xl-4 p-3 mb-4 text-center" key={index}>
                                <FontAwesomeIcon size="3x" className="text-primary" icon={benefit.icon} />
                                <h5 className="fw-bolder mt-4 mb-3">{benefit.title}</h5>
                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: benefit.content }} />
                            </div>
                        );
                    })}
                </div>

            </div>
        </section>
    );
};

export default SectionBenefits;
