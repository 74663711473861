const SectionAbout = () => {
    return (
        <>
            <section>
                <div className="container py-5 px-4 px-lg-5">
                    <div className="row">
                        <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                            <h2 className="fw-bolder mb-5">Tentang Program</h2>
                            <p className="text-muted lh-lg">
                                Eko Kurniawan Khannedy, seorang Technical Architect di Blibli.com, memiliki pengalaman lebih dari 15 tahun berkarya dan 11 tahun berkarir di bidang programming. Dengan keahliannya, akan berkomitmen untuk membantu kamu dalam belajar coding dengan cepat dan terarah. Bergabunglah dengan program belajar ini, untuk menjadi Software Developer Expert yang mampu menguasai beragam teknologi modern yang digunakan oleh banyak startup teknologi dunia.
                            </p>
                        </div>
                        <div className="col-lg-6 my-auto order-first order-lg-last mb-5 mb-lg-auto">
                            <img className="img-fluid" src="/assets/img/img-about-1.jpg" loading="lazy" alt="About 1" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light" style={{ backgroundImage: `url('/assets/img/bg-1.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container py-5 px-4 px-lg-5">
                    <div className="row">
                        <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                            <img className="img-fluid px-lg-5" src="/assets/img/img-about-2.png" loading="lazy" alt="About 2" />
                        </div>
                        <div className="col-lg-6 my-auto">
                            <h2 className="fw-bolder mb-5">Siapa Eko Kurniawan Khannedy?</h2>
                            <ul className="lh-lg text-muted">
                                <li>Technical Architect at Blibli.com (2019 – sekarang)</li>
                                <li>Content Creator at Programmer Zaman Now (2018 – sekarang)</li>
                                <li>Former Senior Principal R&D Engineer at Blibli.com (2018 – 2019)</li>
                                <li>Former Principal R&D Engineer at Blibli.com (2015 – 2017)</li>
                                <li>Former Chief Technology Officer at PT. Starlabs Global Teknologi (2015 – 2017)</li>
                                <li>Former Senior Backend Engineer at PT. Zenith Solusi Global (2015)</li>
                                <li>Former Senior Software Engineer at Midtrans (2012 – 2014)</li>
                                <li>Former Software Engineer at PT. Berca Hardayaperkasa (2011 – 2012)</li>
                                <li>Former Software Engineer at PT. Unity Innovation Technology (2011)</li>
                                <li>Former Dosen at Universitas Komputer Indonesia (UNIKOM) (2010 – 2011)</li>
                                <li>Former Software Engineer at PT. Daya Sinergi Tekno Mandiri  (2010 – 2011)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionAbout;
