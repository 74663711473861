import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink } from "react-router-hash-link";

const Hero = () => {
    return (
        <section className="bg-light pt-5">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-between">
                    <div className="col-lg-6 my-auto">
                        <span className="text-primary fw-bolder h5">KURSUS CODING ZAMAN NOW</span>
                        <h1 className="display-3 fw-bold mb-4">Kuasai Skill Coding ala <span className="text-primary">Startup Unicorn</span></h1>
                        <p className="text-muted lh-lg mb-4">Bangun karirmu menjadi Software Developer Expert dengan teknologi yang digunakan startup teknologi dunia ditemani oleh Eko Kurniawan Khannedy, Technical Architect di Blibli.com.</p>
                        <div className="d-grid gap-2 d-xl-flex  my-4">
                            <HashLink className="btn btn-primary rounded-1 px-3 py-2" to="#invest">
                                <FontAwesomeIcon className="me-2" icon={faCodeBranch} />
                                Gabung Kelas
                            </HashLink>
                            <a className="btn btn-outline-dark rounded-1 px-3 py-2" href="https://wa.me/6285175484822?text=Hai%20Kelasfullstack%2C%20Saya%20ingin%20bergabung%20dengan%20program%20kelasfullstack%2C%20bisa%20tolong%20dibantu%3F" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Tanya CS
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto p-0">
                        <img className="img-fluid d-block mx-auto" src="/assets/img/img-hero.png" alt="Hero" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
